export type PassPhraseInputParams = {
    onSuccess: () => void;
}

const PassPhraseInput = ({ onSuccess }: PassPhraseInputParams): React.ReactElement => {
    const onChange = (event: any) => {
        if (event.target.value.toLocaleLowerCase() === atob(process.env.REACT_APP_PASS_PHRASE || '')) {
            onSuccess();
        }
    }
    
    return (
        <div className="pass-phrase">
            <span className="pass-phrase__label">What's my secret superpower?</span>
            <input type="text" autoFocus className="pass-phrase__input" onKeyUp={onChange} onBlur={onChange}/>
        </div>
    )
}

export default PassPhraseInput;