import PassPhraseInput from './components/PassPhraseInput';
import Divider from './components/Divider';
import Footer from './components/Footer';
import Header from './components/Header';
import useLocalStorage from './hooks/useLocalStorage';
import Overview from './components/Overview';

const App = (): React.ReactElement => {
    const [hasAccess, setHasAccess] = useLocalStorage('hasAccess', '');

    return (
        <>
            <Header title="The Joni Lib" subTitle="est. 23.03.2021 21:12" />
            {!hasAccess && <>
                <Divider />
                <PassPhraseInput onSuccess={() => setHasAccess('true')} />
            </>}
            {!!hasAccess && <Overview />}
            <Footer />
        </>
    )
}

export default App;