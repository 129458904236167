export type HeaderProps = {
    title: string;
    subTitle: string;
}

const Header = ({ title, subTitle }: HeaderProps) => (
    <div className="header">
        <div className="header__logo">
            <img src="assets/junge.png" alt="logo" />
        </div>
        <div className="header__title-area">
            <div className="header__title-area__title">{title}</div>
            <div className="header__title-area__sub-title">{subTitle}</div>
        </div>
    </div>
)

export default Header;