import { useEffect, useState } from "react";
import Section from "./Section";
import { Sections } from "../types/Types";
import rawData from '../data/rawData';
import useLocalStorage from "../hooks/useLocalStorage";

export const BIRTHDAY = new Date('2021-03-23');
const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

const Overview = () => {
  const [data, setData] = useState<Sections>({});
  const [, setHasAccess] = useLocalStorage('hasAccess');

  useEffect(() => {
    const sectionData: Sections = rawData.reduce((acc, entry) => {
      if (!acc[entry.date.toLocaleDateString()]) {
        acc[entry.date.toLocaleDateString()] = {
          date: entry.date,
          ageInDays: (entry.date.getTime() - BIRTHDAY.getTime()) / DAY_IN_MILLISECONDS,
          images: []
        };
      }
      acc[entry.date.toLocaleDateString()].images.push(entry);
      return acc;
    }, {} as Sections);

    setData(sectionData);
  }, []);

  return (
    <>
      <div className="sections">
        {Object.keys(data).reverse().map(date => <Section data={data[date]} key={date} />)}
      </div>
      <div className="reset" onClick={() => setHasAccess('')}/>
    </>
  );
}

export default Overview;