export type ImageProps = {
    src: string;
}

const Image = ({ src }: ImageProps) => (
    <div className={`image ${src.includes('_w') ? 'image--wide' : ''}`}>
        <img src={src} alt={src} loading="lazy" />
    </div>
);

export default Image;