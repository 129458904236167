export type SectionHeaderProps = {
    title: string;
    description: string;
}

const SectionHeader = ({ title, description }: SectionHeaderProps) => (
    <div className="section-header">
        {title} - <span>{description}</span>
    </div>
)

export default SectionHeader;