import { SectionData } from "../types/Types"
import Divider from "./Divider"
import Image from "./Image"
import { BIRTHDAY } from "./Overview"
import SectionHeader from "./SectionHeader"

export type SectionProps = {
    data: SectionData;
}

const Section = ({ data }: SectionProps): React.ReactElement => {
    return (
        <>
            <Divider />
            <SectionHeader
                title={data.date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                description={`
                    d${data.ageInDays}
                    ${data.ageInDays % 7 === 0 ? ' - w' + data.ageInDays / 7 : ''}
                    ${data.date.getDate() === 23 ? ' - m' + (((data.date.getFullYear() - BIRTHDAY.getFullYear()) * 12) + data.date.getMonth() - 2) : ''}
                `}
            />
            <div className="images">
                {data.images.map(image => <Image src={image.imageUrl} key={image.imageUrl} />)}
            </div>
        </>
    )
}

export default Section;